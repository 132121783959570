import { TFunction } from '@getpopsure/i18n-react';
import type { ClaimsAction, RequestAction } from 'constants/actions';
import type { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { ActionResponse, UploadedFile } from 'shared/models/types';
import { ZUploadedDocumentsSchema } from 'shared/models/validations';
import type { BLOCKER, SCREEN } from 'SignupQuestionnaire';
import { z } from 'zod';

const claimTypes = [
  'DOCTORS_VISIT',
  'MEDICATION',
  'HOSPITAL_TREATMENT',
  'PHYSICAL_THERAPY',
  'DENTAL',
  'COST_PLAN',
  'OTHER',
] as const;

export type ClaimType = typeof claimTypes[number];

export type ExpatLongTermClaim = {
  intro: SCREEN;
  claimAmount: number;
  claimAmountOverThreshold: BLOCKER;
  completedMedicalQuestionnaire: boolean;
  uploadMedicalQuestionnaire: UploadedFile;
  medicalQuestionnaireBlocker: BLOCKER;
  claimType: ClaimType;
  additionalInfo: string;
  uploadDocuments: UploadedFile[];
  diagnosis: string;
  payoutDetails: string;
  processing: boolean;
  submitted: boolean;
};

export const ZSubmitClaimInfo = z.union([
  z.object({
    claimAmount: z.number(),
    completedMedicalQuestionnaire: z.boolean().optional(),
    uploadMedicalQuestionnaire: z.array(ZUploadedDocumentsSchema).optional(),
    claimType: z.enum(claimTypes),
    additionalInfo: z.string().optional(),
    uploadDocuments: z.array(ZUploadedDocumentsSchema),
    payoutDetails: z.string(),
    diagnosis: z.string(),
  }),
  z.object({
    claimAmount: z.number(),
    completedMedicalQuestionnaire: z.boolean().optional(),
    uploadMedicalQuestionnaire: z.array(ZUploadedDocumentsSchema).optional(),
    claimType: z.enum(claimTypes),
    additionalInfo: z.string().optional(),
    uploadDocuments: z.array(ZUploadedDocumentsSchema),
    payoutDetails: z.string(),
    diagnosis: z.string(),
  }),
]);

export const basicClaimTypeMapping = (
  t: TFunction
): Partial<
  Record<ClaimType, string | { title: string; description: string }>
> => ({
  COST_PLAN: {
    title: t(
      'claims.expatLongTerm.basicClaimTypeMapping.costPlan.title',
      'Cost plan'
    ),
    description: t(
      'claims.expatLongTerm.basicClaimTypeMapping.costPlan.description',
      'Receive a coverage estimate for your planned treatment'
    ),
  },
  DOCTORS_VISIT: t(
    'claims.expatLongTerm.basicClaimTypeMapping.doctorsVisit',
    "Doctor's visit"
  ),
  MEDICATION: t(
    'claims.expatLongTerm.basicClaimTypeMapping.medication',
    'Medication'
  ),
  HOSPITAL_TREATMENT: {
    title: t(
      'claims.expatLongTerm.basicClaimTypeMapping.hospitalTreatment.title',
      'Hospital treatment'
    ),
    description: t(
      'claims.expatLongTerm.basicClaimTypeMapping.hospitalTreatment.description',
      'including ambulance and emergency room visits'
    ),
  },
  PHYSICAL_THERAPY: t(
    'claims.expatLongTerm.basicClaimTypeMapping.physicalTherapy',
    'Physical therapy'
  ),
  OTHER: t('claims.expatLongTerm.basicClaimTypeMapping.other.title', 'Other'),
});

export const premiumClaimTypeMapping = (
  t: TFunction
): Record<ClaimType, string | { title: string; description: string }> => ({
  COST_PLAN: {
    title: t(
      'claims.expatLongTerm.basicClaimTypeMapping.costPlan.title',
      'Cost plan'
    ),
    description: t(
      'claims.expatLongTerm.basicClaimTypeMapping.costPlan.description',
      'Receive a coverage estimate for your planned treatment'
    ),
  },
  DOCTORS_VISIT: t(
    'claims.expatLongTerm.claimTypeMapping.doctorsVisit',
    "Doctor's visit"
  ),
  MEDICATION: t(
    'claims.expatLongTerm.premiumClaimTypeMapping.medication',
    'Medication'
  ),
  HOSPITAL_TREATMENT: {
    title: t(
      'claims.expatLongTerm.premiumClaimTypeMapping.hospitalTreatment.title',
      'Hospital treatment'
    ),
    description: t(
      'claims.expatLongTerm.premiumClaimTypeMapping.hospitalTreatment.description',
      'including ambulance and emergency room visits'
    ),
  },
  PHYSICAL_THERAPY: t(
    'claims.expatLongTerm.premiumClaimTypeMapping.physicalTherapy',
    'Physical therapy'
  ),
  DENTAL: t('claims.expatLongTerm.premiumClaimTypeMapping.dental', 'Dental'),
  OTHER: t('claims.expatLongTerm.premiumClaimTypeMapping.other.title', 'Other'),
});

export type ProcessingDispatch = ThunkDispatch<
  AppState,
  unknown,
  RequestAction | ClaimsAction
>;

export type ProcessingDispatchAction = () => (
  dispatch: ProcessingDispatch
) => Promise<ActionResponse>;
