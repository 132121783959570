import CheckoutConfirmation from 'components/checkoutConfirmation';
import { useGetMailingPreferences } from 'features/accountV2/containers/MailingPreferences/useGetMailingPreferences';
import { useUpdateMailingPreferences } from 'features/accountV2/containers/MailingPreferences/useUpdateMailingPreferences';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { ValidVerticalUrlParams } from 'models/insurances/types';
import { ChangeEvent, useState } from 'react';
import { TFunction, useSafeTranslation } from 'shared/i18n';

const getSignupSuccessTitle = (
  t: TFunction,
  key: ValidVerticalUrlParams,
  showDental: boolean
): string | undefined => {
  if (showDental) {
    return t('myPolicies.signupSuccess.tk.title', 'Application submitted');
  }

  const title: Partial<Record<ValidVerticalUrlParams, string>> = {
    pension: t(
      'myPolicies.signupSuccess.pension.title',
      'Application submitted'
    ),
  };

  return title[key] ?? undefined;
};

const getSignupSuccessDescription = (
  t: TFunction,
  key: ValidVerticalUrlParams,
  showDental: boolean
): string => {
  if (showDental) {
    return '';
  }

  const descriptions: Partial<Record<ValidVerticalUrlParams, string>> & {
    default: string;
  } = {
    publicHealth: t(
      'myPolicies.signupSuccess.publicHealth.message',
      'We’re processing your application now. You will be receiving a confirmation email shortly.'
    ),
    default: t(
      'myPolicies.signupSuccess.default.message',
      "We've added the policy to your account. You can view the details below."
    ),
    pension: t(
      'myPolicies.signupSuccess.pension.message',
      'We will review your application and follow up in a couple of days with the next steps.'
    ),
  };

  return descriptions[key] ?? descriptions.default;
};

export const CheckoutConfirmationWrapper = () => {
  const { t } = useSafeTranslation();

  const {
    isLoading,
    data: mailingPreferences,
    error,
  } = useGetMailingPreferences();
  const mutation = useUpdateMailingPreferences();

  const [newsletterSubscription, setNewsletterSubscription] = useState<
    boolean | undefined
  >(mailingPreferences?.customer.topics.every((topic) => topic.subscribed));

  const [newsletterFeedback, setNewsletterFeedback] = useState<string | null>(
    null
  );

  const showDental = useQueryParamValue('showDental') === '1';
  const signupSuccessParam = useQueryParamValue(
    'signupSuccess'
  ) as ValidVerticalUrlParams;

  if (!signupSuccessParam) {
    return null;
  }

  const showNewsletterFeedback = () => {
    setTimeout(() => setNewsletterFeedback(null), 3000);

    setNewsletterFeedback(
      !newsletterSubscription
        ? t('newsletter.subscribed.message', '🎉 Subscribed!')
        : t('newsletter.unsubscribed.message', '👋 Unsubscribed!')
    );
  };

  const onUpdateMailingPreferences = async (
    ev: ChangeEvent<HTMLInputElement>
  ) => {
    if (!mailingPreferences) return;

    const { checked } = ev.target;

    setNewsletterSubscription(checked);

    const options = mailingPreferences.topics.reduce(
      (all, topic) => ({
        ...all,
        [topic.identifier]: checked,
      }),
      {}
    );

    await mutation.mutateAsync(options);

    if (!error?.message) {
      showNewsletterFeedback();
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="mb56">
      <CheckoutConfirmation
        title={getSignupSuccessTitle(t, signupSuccessParam, showDental)}
        description={getSignupSuccessDescription(
          t,
          signupSuccessParam,
          showDental
        )}
        showFeatherInfoCard={
          signupSuccessParam === 'publicHealth' && !showDental
        }
        mailingPreferences={mailingPreferences}
        newsletterSubscription={newsletterSubscription ?? false}
        onUpdateMailingPreferences={onUpdateMailingPreferences}
        feedback={newsletterFeedback}
        newsletterLoading={isLoading}
        newsletterError={error?.message}
        showDentalCard={showDental}
        insuranceType={signupSuccessParam}
      />
    </div>
  );
};
